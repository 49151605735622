/* global tracking */

import store from '@/store';
import { getUserFromJwtToken } from '@/flows/Authentication/helpers/AuthHelpers';
import {
    MixpanelService,
    EVENTS as _EVENTS,
} from '@/core/services/MixPanelService/MixPanelService';

export const EVENTS = _EVENTS;
export const TrackingService = (function () {
    /** Constructor */
    function TrackingService(user = null) {
        if (!user) {
            user = store.getters.user;

            if (!user) {
                user = getUserFromJwtToken(
                    localStorage.getItem('userDataToken'),
                );
            }
        }

        if (!user) {
            console.error('TS: unable to get user');
            return;
        }

        if (user.isGuest) {
            console.error('TS: user is guest');
            return;
        }

        if (!user.email && !user.username) {
            console.error('TS: corrupted user');
            return;
        }

        this.user = user;

        this.mixpanelId = user.email ? user.email : user.username;
    }

    TrackingService.prototype.track = function (event = '', args = {}) {
        new MixpanelService(this.user).track(event, args);
        // @TODO: implement tracking calls for Mixpanel and Klaviyo
        // <!-- Klaviyo -->
        // <script async type="text/javascript" src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=Tvk9hs"></script>

        // <script>
        //     klaviyo.push(['identify', {
        //         // Change the line below to dynamically print the user's email.
        //         'email': '{{ email }}'
        //     }]);
        // </script>
    };

    return TrackingService;
})();
