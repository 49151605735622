import { mapActions } from 'vuex';

export const MathRunnerSSE = {
    data: () => ({
        evtSource: null,
        initSSEOnMount: true,
        isSSELoading: false,
    }),
    mounted() {
        if (this.initSSEOnMount) {
            this.initSSE();
        }
    },
    beforeUnmount() {
        this.closeSSEConnection();
    },
    methods: {
        ...mapActions('v2/mathRunner', ['addSSEvent', 'clearSSEEvents']),
        closeSSEConnection() {
            this.evtSource?.close();

            this.clearSSEEvents();
        },
        async initSSE() {
            this.closeSSEConnection();

            const userId = this.$user.userId;

            if (!userId) {
                console.warn('SSE - No user id.');

                return false;
            }

            this.evtSource = new EventSource(
                `${import.meta.env.VITE_API_URL}sse/math-runner/${userId}`,
            );

            this.evtSource.onmessage = (event) => {
                try {
                    this.onEventData(JSON.parse(event.data));
                } catch (error) {
                    console.error(
                        'Not possible to parse event json',
                        error,
                        event,
                    );
                }
            };

            this.evtSource.onopen = (event) => {
                console.info('SSE connection opened', event.target);
            };

            this.evtSource.onerror = (error) => {
                console.info('SSE connection error', error);
            };
        },
        async onEventData(data) {
            const handler = {
                ['runnerInvite']: () => {
                    this.$store.dispatch('v2/mathRunner/addInvite', data);
                },

                ['runnerDeclinedInvite']: () => {
                    this.$store.dispatch(
                        'v2/mathRunner/updateSentInvites',
                        data,
                    );
                },

                ['runnerStarted']: () => {
                    this.$store.dispatch('v2/mathRunner/updateInvites', data);
                },

                ['heartbeat']: () => null,

                default: () => this.addSSEvent(data),
            };

            await (handler[data.type] || data.default)();
        },
    },
};
