<template>
    <button class="action" :class="classes" @click="$emit('click')">
        <img class="icon" :src="imageUrl" alt="action" />
    </button>
</template>

<script>
export const ActionSize = {
    Small: 'small', // default
    Medium: 'medium',
    Large: 'large',
};
export const ActionColor = {
    Yellow: 'yellow', // default
    Red: 'red',
    Blue: 'blue',
    Green: 'green',
};
export default {
    name: 'ActionSmall',
    props: {
        size: {
            type: String,
            default: ActionSize.Small,
        },
        color: {
            type: String,
            default: ActionColor.Yellow,
        },
        disabled: {
            default: false,
        },
        pressed: {
            default: false,
        },
        imageUrl: {
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        classes() {
            const classes = [
                `action-size-${this.size}`,
                `action-color-${this.color}`,
            ];

            if (this.disabled) {
                classes.push('action-disabled');
            }

            if (this.pressed) {
                classes.push('action-pressed');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
@import '../swatches.css';
.action {
    position: relative;
    cursor: pointer;
    border: 2px solid $--Neutral-100;
    border-radius: 50%;
    box-shadow:
        0 0 0 2px $--Neutral-700,
        0 2px 0 2px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transform: translateY(1px);
        transition: transform 0.1s;
    }
}
.action-size-small {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;

    .icon {
        width: 24px;
        height: 24px;
    }
}
.action-size-medium {
    width: 52px;
    height: 52px;
    max-width: 52px;
    max-height: 52px;

    .icon {
        width: 32px;
        height: 32px;
    }

    @include mq($h-max-under-chromebook, max, height) {
        width: 40px;
        height: 40px;

        .icon {
            width: 24px;
            height: 24px;
        }
    }
}
.action-size-large {
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;

    .icon {
        width: 32px;
        height: 32px;
    }
}
.action-color-yellow {
    background: $--Yellow-500;

    &.action-pressed {
        background: $--Yellow-600;
    }
}
.action-color-red {
    background: $--Red-300;

    &.action-pressed {
        background: $--Red-500;
    }
}
.action-color-blue {
    background: $--Blue-500;

    &.action-pressed {
        background: $--Blue-700;
    }
}
.action-color-green {
    background: $--Green-500;

    &.action-pressed {
        background: $--Green-700;
    }
}
.action-disabled {
    background: $--Neutral-300;

    .icon {
        opacity: 0.5;
    }
}
</style>
