/* global mixpanel */

import store from '@/store';
import { getUserFromJwtToken } from '@/flows/Authentication/helpers/AuthHelpers';
import { getFormattedLocalTime } from '@/core/helpers/utils';
import { AUTH_MP_EVENTS, AUTH_MP_METHODS } from './events/AuthEvents';
import { USER_MP_EVENTS, USER_MP_METHODS } from './events/UserEvents';
import {
    HOST_LIVE_GAME_MP_EVENTS,
    HOST_LIVE_GAME_MP_METHODS,
} from './events/HostLiveGameEvents';
import {
    HOST_SPG_MP_EVENTS,
    HOST_SPG_MP_METHODS,
} from './events/HostSPGEvents';
import {
    HOST_SPPLAYLIST_MP_EVENTS,
    HOST_SPPLAYLIST_MP_METHODS,
} from './events/HostSPPlaylistEvents';
import { TEAM_MP_EVENTS, TEAM_MP_METHODS } from './events/TeamEvents';
import {
    STUDENT_ONBOARDING_MP_EVENTS,
    STUDENT_ONBOARDING_MP_METHODS,
} from './events/StudentOnboardingEvents';
import {
    TEACHER_REFERRAL_MP_EVENTS,
    TEACHER_REFERRAL_MP_METHODS,
} from './events/TeacherReferralEvents';
import {
    REACTIONS_SPG_MP_EVENTS,
    REACTIONS_SPG_MP_METHODS,
} from './events/StudentReactions';
import {
    TEACHER_ONBOARDING_MP_EVENTS,
    TEACHER_ONBOARDING_METHODS,
} from './events/TeacherOnboarding';
import { PROFILE_MP_EVENTS, PROFILE_MP_METHODS } from './events/ProfileEvents';
import {
    TEACHER_ONBOARDING_SURVEY_MP_EVENTS,
    TEACHER_ONBOARDING_SURVEY_MP_METHODS,
} from '@/core/services/MixPanelService/events/TeacherOnboardingSurvey';
import {
    TEACHER_ONBOARDING_REGISTER_MP_EVENTS,
    TEACHER_ONBOARDING_REGISTER_MP_METHODS,
} from '@/core/services/MixPanelService/events/TeacherOnboardingRegister';
import {
    AB_TEST_MP_EVENTS,
    AB_TEST_MP_METHODS,
} from '@/core/services/MixPanelService/events/AbTestEvents';
import {
    CLASS_LISTS_MP_EVENTS,
    CLASS_LISTS_MP_METHODS,
} from '@/core/services/MixPanelService/events/ClassListsEvents';
import {
    HOME_GAME_MP_EVENTS,
    HOME_GAME_MP_METHODS,
} from '@/core/services/MixPanelService/events/HomeGameEvents';
import {
    SHOP_MP_EVENTS,
    SHOP_MP_METHODS,
} from '@/core/services/MixPanelService/events/ShopEvents';
import {
    STUDENT_SCHOOL_TAB_MP_EVENTS,
    STUDENT_SCHOOL_TAB_MP_METHODS,
} from '@/core/services/MixPanelService/events/StudentSchoolTabEvents';
import {
    HOME_GAME_V10_MP_EVENTS,
    HOME_GAME_V10_MP_METHODS,
} from '@/core/services/MixPanelService/events/HomeGame';
import {
    BATTLEPASS_MP_EVENTS,
    BATTLEPASS_MP_METHODS,
} from '@/core/services/MixPanelService/events/BattlepassMixpanelEvents';
import moment from 'moment';
import {
    MATH_RUNNER_MP_EVENTS,
    MATH_RUNNER_MP_METHODS,
} from '@/core/services/MixPanelService/events/MathRunnerEvents';
import {
    SOLO_TRACK_EVENTS,
    SOLO_TRACK_METHODS,
} from '@/core/services/MixPanelService/events/SoloTrackEvents';
import {
    HOME_PLAY_EVENTS,
    HOME_PLAY_METHODS,
} from '@/core/services/MixPanelService/events/HomePlayEvents';
import {
    CARD_COLLECTION_EVENTS,
    CARD_COLLECTION_METHODS,
} from '@/core/services/MixPanelService/events/CardCollectionEvents';
import {
    CERTIFICATE_PRINTOUT_EVENTS,
    CERTIFICATE_PRINTOUT_METHODS,
} from './events/TblResultCertificateEvents';
import {
    PREMIUM_EVENTS,
    PREMIUM_METHODS,
} from '@/core/services/MixPanelService/events/PremiumEvents';
import {
    STUDENT_PARK_EVENTS,
    STUDENT_PARK_METHODS,
} from '@/core/services/MixPanelService/events/StudentParkEvents';
import {
    BUNDLE_SALES_EVENTS,
    BUNDLE_SALES_METHODS,
} from '@/core/services/MixPanelService/events/BundleSalesEvents';
import {
    AI_TUTOR_MP_EVENTS,
    AI_TUTOR_MP_METHODS,
} from '@/student/widgets/AiTutor/mixpanel/AiTutorEvents';

export const EVENTS = {
    ...AUTH_MP_EVENTS,
    ...USER_MP_EVENTS,
    ...HOST_LIVE_GAME_MP_EVENTS,
    ...HOST_SPG_MP_EVENTS,
    ...HOST_SPPLAYLIST_MP_EVENTS,
    ...TEAM_MP_EVENTS,
    ...STUDENT_ONBOARDING_MP_EVENTS,
    ...TEACHER_REFERRAL_MP_EVENTS,
    ...REACTIONS_SPG_MP_EVENTS,
    ...TEACHER_ONBOARDING_MP_EVENTS,
    ...TEACHER_ONBOARDING_SURVEY_MP_EVENTS,
    ...TEACHER_ONBOARDING_REGISTER_MP_EVENTS,
    ...PROFILE_MP_EVENTS,
    ...AB_TEST_MP_EVENTS,
    ...CLASS_LISTS_MP_EVENTS,
    ...HOME_GAME_MP_EVENTS,
    ...SHOP_MP_EVENTS,
    ...HOME_GAME_V10_MP_EVENTS,
    ...STUDENT_SCHOOL_TAB_MP_EVENTS,
    ...BATTLEPASS_MP_EVENTS,
    ...MATH_RUNNER_MP_EVENTS,
    ...SOLO_TRACK_EVENTS,
    ...CARD_COLLECTION_EVENTS,
    ...HOME_PLAY_EVENTS,
    ...CERTIFICATE_PRINTOUT_EVENTS,
    ...PREMIUM_EVENTS,
    ...STUDENT_PARK_EVENTS,
    ...BUNDLE_SALES_EVENTS,
    ...AI_TUTOR_MP_EVENTS,
};

const ALLOWED_STUDENT_EVENTS = [
    USER_MP_EVENTS.ADD_AB_TEST,
    USER_MP_EVENTS.SUBSCRIBE_ATTEMPT,
    USER_MP_EVENTS.PARENT_INFO_MODAL_OPENED,
    USER_MP_EVENTS.PARENT_INFO_MODAL_CLOSED,
    USER_MP_EVENTS.STUDENT_SAW_TBL_END_GAME,
    USER_MP_EVENTS.STUDENT_SAW_MORE_99MATH,
    AUTH_MP_EVENTS.SIGN_UP,
    AUTH_MP_EVENTS.LOG_IN,
    AUTH_MP_EVENTS.LOG_OUT,
    STUDENT_ONBOARDING_MP_EVENTS.STUDENT_GRADE_SELECT,
    HOST_SPG_MP_EVENTS.SPG_STARTED_PLAYING,
    HOME_GAME_MP_EVENTS.VIEW_BUY_ENERGY_SCREEN,
    HOME_GAME_MP_EVENTS.VIEW_PREMIUM_PARENT_SCREEN,
    HOME_GAME_MP_EVENTS.VIEW_PREMIUM_CREDIT_CARD_SCREEN,
    HOME_GAME_MP_EVENTS.PARENT_BUYS_PREMIUM,
    HOME_GAME_MP_EVENTS.SET_USER_PRICE_TEST,
    USER_MP_EVENTS.STUDENT_JOINED_CLASS,
    USER_MP_EVENTS.VIEWED_OUT_OF_ENERGY_MODAL,
    USER_MP_EVENTS.OPEN_MOBILE_APP,
    USER_MP_EVENTS.CONTINUE_WITH_BROWSER,
    HOST_LIVE_GAME_MP_EVENTS.LIVE_GAME_STARTED_PLAYING,
    ...Object.values(SHOP_MP_EVENTS),
    ...Object.values(HOME_GAME_V10_MP_EVENTS),
    ...Object.values(BATTLEPASS_MP_EVENTS),
    ...Object.values(MATH_RUNNER_MP_EVENTS),
    ...Object.values(SOLO_TRACK_EVENTS),
    ...Object.values(CARD_COLLECTION_EVENTS),
    ...Object.values(AB_TEST_MP_EVENTS),
    ...Object.values(PREMIUM_EVENTS),
    ...Object.values(STUDENT_PARK_EVENTS),
    ...Object.values(BUNDLE_SALES_EVENTS),
    ...Object.values(AI_TUTOR_MP_EVENTS),
];

const eventExistsInEventList = (event) => {
    for (let eventKey in EVENTS) {
        if (EVENTS[eventKey] && EVENTS[eventKey] === event) {
            return true;
        }
    }

    return false;
};

const eventExistsInSubEventList = (event, LIST) => {
    for (let eventKey in EVENTS) {
        if (LIST[eventKey] && LIST[eventKey] === event) {
            return true;
        }
    }

    return false;
};

export const MixpanelService = (function () {
    /** Constructor */
    function MixpanelService(user = null) {
        if (!user) {
            user = store.getters.user;

            if (!user) {
                user = getUserFromJwtToken(
                    localStorage.getItem('userDataToken'),
                );
            }
        }

        if (!user) {
            console.error('MPS: unable to get user');
            return;
        }

        if (user.isGuest) {
            console.error('MPS: user is guest');
            return;
        }

        if (!user.email && !user.username) {
            console.error('MPS: corrupted user');
            return;
        }

        this.user = user;

        this.mixpanelId = user.email ? user.email : user.username;
    }

    MixpanelService.prototype.track = function (event = '', args = {}) {
        if (!this.user || !this.mixpanelId) {
            console.error(
                `MPS: Trying to send event '${event}' with anonymous user`,
            );

            return;
        }
        if (!eventExistsInEventList(event)) {
            console.error(`MPS: event '${event}' is not listed in EVENTS`);
            return;
        }

        if (typeof privateMethods[event] !== 'function') {
            console.error(`MPS: event '${event}' is not implemented`);
            return;
        }

        if (sessionStorage.getItem('tutorial')) {
            const eventFromTutorial = eventExistsInSubEventList(
                event,
                STUDENT_ONBOARDING_MP_EVENTS,
            );

            const eventFromTeacherTutorial = eventExistsInSubEventList(
                event,
                TEACHER_ONBOARDING_MP_EVENTS,
            );

            if (!eventFromTutorial && !eventFromTeacherTutorial) {
                return;
            }
        }

        privateMethods.initMixpanel.call(this, event, args);

        let objData = {
            'Local hours': getFormattedLocalTime(),
        };

        if (this.user.role === 'student') {
            const stData = store.getters['v2/soloTrack/studentSoloTrackData'];

            objData = {
                ...objData,
                'ST available': !!stData,
            };

            // only send whitelisted events
            if (!ALLOWED_STUDENT_EVENTS.includes(event)) {
                return;
            }

            if ((this.user || {}).ageInfo || args.age) {
                const age = ((this.user || {}).ageInfo || {}).age || args.age;

                objData = {
                    ...objData,
                    Age: age,
                };
            }
        }

        mixpanel.register(objData);

        privateMethods[event].call(this, args);

        mixpanel.people.set({ 'Last activity': moment().format() });
    };

    const privateMethods = {
        initMixpanel(event = '', args = {}) {
            mixpanel.identify(this.mixpanelId);

            const excludeEvents = [EVENTS.SIGN_UP, EVENTS.LOG_OUT];

            if (!excludeEvents.includes(event)) {
                privateMethods.updateUserProfile.call(this, event, args);
            }
        },
        updateUserProfile(eventName = '', args = {}) {
            // Set super properties for all events.
            mixpanel.register_once({
                email: this.user.email,
                username: this.user.username,
            });

            mixpanel.register({
                name: `${this.user.firstName} ${this.user.lastName}`,
                role: this.user.role,
                playerName: this.user.playerName,
                FEAppVersion: import.meta.env.VITE_APP_VERSION,
                URL: location.toString(),
            });

            // Set up user profile.
            const commonData = {
                $name: `${this.user.firstName} ${this.user.lastName}`,
                $date: new Date().toISOString(),
                $role: this.user.role,
                username: this.user.username,
                playerName: this.user.playerName,
                FEAppVersion: import.meta.env.VITE_APP_VERSION,
                Classes:
                    this.user.role === 'student'
                        ? Object.keys(this.user.studentInfo?.classLists || {})
                        : this.user.teacherClasses?.map((el) => el.classCode),
            };

            if (this.user.priceTestVersion?.name) {
                commonData['Price test version'] =
                    this.user.priceTestVersion.name;
            }

            if (this.user.role === 'teacher') {
                mixpanel.people.set({
                    ...commonData,
                    'Teaching Grades': this.user.teachingGrades,
                });
            } else if (
                this.user.role === 'student' &&
                (this.user.ageInfo || eventName === EVENTS.STUDENT_SET_AGE)
            ) {
                const age =
                    eventName !== EVENTS.STUDENT_SET_AGE
                        ? this.user.ageInfo.age
                        : args.age;

                mixpanel.people.set({
                    ...commonData,
                    Age: age,
                });
            } else {
                mixpanel.people.set(commonData);
            }

            mixpanel.people.set_once({
                $email: this.user.email,
                games_played: 0,
                'Tests saved': 0,
            });
        },
        ...AUTH_MP_METHODS,
        ...USER_MP_METHODS,
        ...HOST_LIVE_GAME_MP_METHODS,
        ...HOST_SPG_MP_METHODS,
        ...HOST_SPPLAYLIST_MP_METHODS,
        ...TEAM_MP_METHODS,
        ...STUDENT_ONBOARDING_MP_METHODS,
        ...TEACHER_REFERRAL_MP_METHODS,
        ...REACTIONS_SPG_MP_METHODS,
        ...TEACHER_ONBOARDING_METHODS,
        ...TEACHER_ONBOARDING_SURVEY_MP_METHODS,
        ...TEACHER_ONBOARDING_REGISTER_MP_METHODS,
        ...PROFILE_MP_METHODS,
        ...AB_TEST_MP_METHODS,
        ...CLASS_LISTS_MP_METHODS,
        ...HOME_GAME_MP_METHODS,
        ...SHOP_MP_METHODS,
        ...HOME_GAME_V10_MP_METHODS,
        ...STUDENT_SCHOOL_TAB_MP_METHODS,
        ...BATTLEPASS_MP_METHODS,
        ...MATH_RUNNER_MP_METHODS,
        ...SOLO_TRACK_METHODS,
        ...CARD_COLLECTION_METHODS,
        ...HOME_PLAY_METHODS,
        ...CERTIFICATE_PRINTOUT_METHODS,
        ...PREMIUM_METHODS,
        ...STUDENT_PARK_METHODS,
        ...BUNDLE_SALES_METHODS,
        ...AI_TUTOR_MP_METHODS,
    };

    return MixpanelService;
})();
