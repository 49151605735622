<template>
    <div class="payment-modal-body">
        <div class="payment-modal-content-left">
            <span class="payment-modal-content-heading">
                <span class="payment-modal-content-heading__green">
                    {{ isParent ? 'Transform' : 'Upgrade' }}
                </span>
                {{
                    isParent ? `your kid's screen time` : `${playerFirstName}'s`
                }}
            </span>
            <span class="payment-modal-content-heading">
                {{
                    isParent
                        ? 'into a LEARNING EXPERIENCE'
                        : 'learning experience'
                }}
            </span>
            <ParentInfo />
            <ul class="payment-modal-content-list">
                <li>
                    <span class="payment-modal-content-text">
                        <span class="payment-modal-content-text__green">
                            Boost
                        </span>
                        {{
                            isParent
                                ? 'motivation by turning math into gaming'
                                : 'your playtime and recharge faster!'
                        }}
                    </span>
                </li>
                <li>
                    <span class="payment-modal-content-text">
                        {{ isParent ? '' : 'Unlock' }}
                        <span class="payment-modal-content-text__green">
                            {{
                                isParent
                                    ? 'Tailored challenges'
                                    : 'exclusive rewards'
                            }}
                        </span>
                        {{
                            isParent
                                ? `for your child's skills`
                                : 'for ultimate math motivation!'
                        }}
                    </span>
                </li>
                <li>
                    <span class="payment-modal-content-text">
                        <span class="payment-modal-content-text__green">
                            {{
                                isParent
                                    ? 'Inbuilt tutoring tool'
                                    : 'Master math'
                            }}
                        </span>
                        {{
                            isParent
                                ? 'for when your kid needs a little help'
                                : 'skills at lightning speed!'
                        }}
                    </span>
                </li>
            </ul>
            <div class="payment-modal-images">
                <img
                    src="/images/payment-modal-v2/triple_play.png"
                    alt="triple play time"
                />
                <img
                    src="/images/payment-modal-v2/rare_pets.png"
                    alt="rare pets"
                />
                <img
                    src="/images/payment-modal-v2/unique_pets.png"
                    alt="unique avatars"
                />
            </div>
            <PrivacyMobile />
        </div>
        <div class="payment-modal-content-right">
            <div class="payment-modal-card">
                <img
                    src="/ui/game/180/icon_mathPass.svg"
                    alt="math pass"
                    class="payment-modal-math-pass"
                />
                <span class="payment-modal-card-heading">Math Pass</span>
                <div class="payment-modal-card-price-container">
                    <span class="payment-modal-card-price">
                        {{ getCardPrice(selectedPriceOption) }}
                        <span class="payment-modal-card-price__discount">
                            {{ getDiscountForPrice(selectedPriceOption) }}
                        </span>
                    </span>
                    <span class="payment-modal-card-price-annually">
                        {{ getBillingForPrice(selectedPriceOption) }}
                    </span>
                </div>
                <button
                    class="payment-modal-checkout-btn"
                    @click="handleNextStep"
                >
                    {{
                        isFacebookSignup
                            ? 'Start 7-day free trial'
                            : 'Go to checkout'
                    }}
                </button>
                <span class="payment-modal-checkout-cancelled">
                    {{
                        optionIsLifetime(selectedPriceOption)
                            ? 'Subscription is paid for once'
                            : optionIsYearly(selectedPriceOption)
                              ? 'Subscription renews yearly'
                              : 'Subscription renews monthly'
                    }}
                    <span v-if="!optionIsLifetime(selectedPriceOption)">
                        <br />
                        (
                        <a
                            href="http://legal.99math.com/terms-of-use#subscription"
                        >
                            Cancel anytime
                        </a>
                        in settings )
                    </span>
                </span>
                <div class="payment-modal-checkboxes">
                    <div
                        v-for="priceItem in checkboxOptions"
                        :key="priceItem.stripe.id"
                        class="payment-modal-checkbox"
                    >
                        <input
                            :id="priceItem.stripe.id"
                            v-model="selectedPriceOption"
                            type="radio"
                            name="selectedPriceOption"
                            :value="priceItem"
                        />
                        <label :for="priceItem.stripe.id">
                            {{ getNameForPrice(priceItem) }}
                            <br />
                            <span
                                v-if="!optionIsLifetime(priceItem)"
                                class="payment-modal-checkbox-price"
                            >
                                {{
                                    optionIsLifetime(priceItem)
                                        ? `$${
                                              priceItem.stripe?.unit_amount /
                                              100
                                          }`
                                        : `$${getPerMonth(priceItem)}`
                                }}
                                <span
                                    v-if="!optionIsLifetime(priceItem)"
                                    class="payment-modal-checkbox-price__small"
                                >
                                    {{ getShortHandForOption(priceItem) }}
                                </span>
                            </span>
                            <span
                                v-if="optionIsLifetime(priceItem)"
                                class="payment-modal-checkbox-price-annually"
                            >
                                {{
                                    optionIsLifetime(priceItem)
                                        ? 'Pay once'
                                        : ''
                                }}
                            </span>
                        </label>
                    </div>
                </div>

                <div class="payment-modal-checkbox-hint-container">
                    <span
                        :class="
                            discount
                                ? 'payment-modal-checkbox-hint'
                                : 'payment-modal-checkbox-hint--hidden'
                        "
                    >
                        Coupon applied
                    </span>
                    <span class="payment-modal-checkbox-hint">Save 50%</span>
                    <span
                        v-if="checkboxOptions.length === 3"
                        class="payment-modal-checkbox-hint"
                    >
                        Best Value
                    </span>
                </div>

                <span
                    v-if="lastStudentClass && teacherInfo"
                    class="payment-modal-text"
                >
                    {{
                        `${user.firstName} & ${
                            lastStudentClass.students.length - 1
                        } other classmates from ${
                            teacherInfo.teacherInfo?.title
                        } ${
                            teacherInfo.accountInfo?.lastName
                        }'s class play 99math!`
                    }}
                </span>
                <span v-else class="payment-modal-text">
                    {{
                        isParent
                            ? '5000+ parents subscribed last week!'
                            : '5000+ parents upgraded last week!'
                    }}
                </span>
            </div>
            <div class="privacy">
                <a :href="$getTermsOfUseLink" target="_blank" rel="noopener">
                    {{ $t('auth.termsAndConditions') }}
                </a>

                {{ $t('general.and') }}

                <a :href="getPrivacyPolicyLink" target="_blank" rel="noopener">
                    {{ $t('auth.privacyPolicy') }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PrivacyMobile from '@/student/components/PrivacyMobile.vue';
import { getPrivacyPolicyLink } from '@/core/helpers/utils';
import moment from 'moment/moment';
import ParentInfo from '../../PaymentModal/ParentInfo/ParentInfo.vue';
import { isParent } from '@/flows/Authentication/helpers/AuthHelpers';

export default {
    components: { PrivacyMobile, ParentInfo },
    props: {
        unauthorizedAccount: Boolean,
        unauthorizedUserName: String,
        lastStudentClass: Object,
        priceTestVersion: Array,
        discount: Object,
        teacherInfo: Object,
    },
    emits: ['nextStep', 'selectedPlanChange'],
    data() {
        return {
            selectedPriceOption: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
            currentLanguage: 'getCurrentLanguage',
            priceTest: 'getUsersPriceTest',
        }),
        ...mapGetters(['getReferral', 'getTeachingGrades']),
        isFacebookSignup() {
            return this.user?.flags?.parentSignupFacebook;
        },
        isParent() {
            return isParent(this.user);
        },
        getPrivacyPolicyLink() {
            return getPrivacyPolicyLink(this.currentLanguage);
        },
        playerFirstName() {
            return this.unauthorizedAccount
                ? this.unauthorizedUserName
                : this.user?.firstName;
        },
        checkboxOptions() {
            if (this.isFacebookSignup) {
                return (
                    this.priceTest?.products ||
                    this.priceTestVersion?.products ||
                    []
                ).filter((po) => !this.optionIsLifetime(po));
            }
            return (
                this.priceTest?.products ||
                this.priceTestVersion?.products ||
                []
            );
        },
        priceTestAndPriceTestVersion() {
            return this.priceTest || this.priceTestVersion;
        },
        discountAmount() {
            return (
                moment(this.discount?.redeem_by * 1000).isAfter(moment()) *
                    this.discount?.percent_off *
                    0.01 || 1
            );
        },
    },
    watch: {
        priceTestAndPriceTestVersion: {
            immediate: true,
            handler: function (newVal) {
                if (newVal?.products?.length) {
                    if (this.discount) {
                        const monthlyOption = newVal.products.find(
                            (item) =>
                                item?.stripe?.recurring?.interval === 'month' &&
                                item?.stripe?.recurring?.interval_count === 1,
                        );

                        if (monthlyOption) {
                            this.selectedPriceOption = monthlyOption;
                        }
                    } else {
                        const yearlyOption = newVal.products.find(
                            (item) =>
                                item?.stripe?.recurring?.interval === 'year',
                        );

                        if (yearlyOption) {
                            this.selectedPriceOption = yearlyOption;
                        }
                    }
                }
            },
        },
        selectedPriceOption: {
            immediate: true,
            handler: function (newVal) {
                this.$emit('selectedPlanChange', newVal);
            },
        },
    },
    methods: {
        getCardPrice(priceItem) {
            if (!priceItem) return;

            return this.optionIsLifetime(priceItem) ||
                this.optionIsYearly(priceItem)
                ? `$${priceItem.stripe?.unit_amount / 100}*`
                : `$${this.getPerMonth(priceItem, this.discountAmount)}`;
        },
        getDiscountForPrice(priceItem) {
            if (!priceItem) return;

            return this.optionIsLifetime(priceItem)
                ? '$399.99'
                : this.optionIsYearly(priceItem)
                  ? '$120.00'
                  : this.discount
                    ? `$${this.getPerMonth(priceItem)}`
                    : '';
        },

        optionIsWeekly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'week';
        },
        optionIsYearly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'year';
        },
        optionIsLifetime(priceItem) {
            return !!priceItem?.stripe?.metadata?.lifetimeSubscription;
        },
        optionIsMonthly(priceItem) {
            return priceItem?.stripe?.recurring?.interval === 'month';
        },
        getPerMonth(priceItem, discount = 1) {
            if (!priceItem) return;

            const months =
                priceItem.stripe.recurring?.interval === 'year'
                    ? priceItem.stripe.recurring.interval_count * 12
                    : priceItem.stripe.recurring?.interval_count;

            return ((priceItem.stripe.unit_amount * discount) / 100 / months)
                .toString()
                .match(/^\d+(?:\.\d{0,2})?/)[0];
        },
        getBillingForPrice(priceItem) {
            if (this.optionIsLifetime(priceItem)) {
                return 'Billed once';
            }

            if (this.optionIsYearly(priceItem)) {
                return this.isFacebookSignup
                    ? 'Billed in 7 days'
                    : `Billed now`;
            }

            if (this.optionIsWeekly(priceItem)) {
                return this.isFacebookSignup
                    ? 'Billed in 7 days'
                    : 'Billed weekly';
            }

            if (this.optionIsMonthly(priceItem) && this.discount) {
                return `First month & $${this.getPerMonth(
                    priceItem,
                )} thereafter`;
            }

            if (this.optionIsMonthly(priceItem)) {
                return 'Billed monthly';
            }

            return `Billed $${this.getPerMonth(priceItem)} / month`;
        },
        getShortHandForOption(priceItem) {
            const recurring = priceItem?.stripe?.recurring;

            if (recurring?.interval === 'week') {
                return '/week';
            }
            if (recurring?.interval === 'month') {
                if (recurring?.interval_count === 1) {
                    return '/m';
                }

                if (recurring?.interval_count === 6) {
                    return '/half-a-year';
                }
            }

            if (recurring?.interval === 'year') {
                return '/m';
            }

            if (priceItem?.stripe?.metadata?.lifetimeSubscription) {
                return 'Once';
            }
        },
        getNameForPrice(priceItem) {
            const recurring = priceItem?.stripe?.recurring;

            if (recurring?.interval === 'week') {
                return 'Weekly';
            }
            if (recurring?.interval === 'month') {
                if (recurring?.interval_count === 1) {
                    return 'Monthly';
                }

                if (recurring?.interval_count === 6) {
                    return 'Half Year';
                }
            }

            if (recurring?.interval === 'year') {
                return 'Yearly';
            }

            if (priceItem?.stripe?.metadata?.lifetimeSubscription) {
                return 'Lifetime';
            }
        },
        handleNextStep() {
            this.$emit('nextStep');
        },
    },
};
</script>
<style lang="scss" scoped>
.payment-modal-body {
    display: flex;
    align-items: center;
    max-width: 1366px;
    width: 100%;
    margin: auto 0;

    @media screen and (min-width: $w-min-tablet) {
        height: 100%;
    }

    @media screen and (min-width: $w-min-tablet) {
        height: 100%;
    }

    @media screen and (max-width: $w-max-tablet) {
        flex-direction: column;
        max-width: 100%;
        margin: 0;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        height: unset;
        margin-top: 30px;
    }

    @media screen and (min-width: $w-max-phone) {
        flex-direction: row;
    }
}
.payment-modal-content-left {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 54px;

    @media screen and (max-width: $w-max-tablet) {
        margin-top: 2rem;
        width: 90%;
        max-width: 600px;
        padding-left: unset;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        width: 50%;
        padding-left: 10px;
        margin-top: 0;
    }

    @media screen and (max-width: $w-max-phone) {
        width: 100%;
        padding-left: unset;
    }
}
.payment-modal-content-right {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;

    @media screen and (min-width: $w-min-tablet) {
        margin-top: 20px;
    }

    @media screen and (max-width: $w-max-tablet) {
        width: 100%;
        align-items: center;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        width: 50%;
        margin-top: 0;
    }
}
.payment-modal-content-heading {
    margin: 0;
    font-family: $secondary-font-family;
    font-size: 50px;
    font-style: italic;
    font-weight: 700;
    line-height: 115%;
    color: #fff;

    &__green {
        color: #2be480;
        text-transform: uppercase;
    }

    @media screen and (max-width: $w-max-tablet) {
        font-size: 22px;
        margin-left: 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 22px;
    }
}
.payment-modal-content-text {
    font-family: $secondary-font-family;
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    line-height: 150%;
    color: #fff;

    &__green {
        color: #2be480;
    }

    @media screen and (max-width: $w-max-tablet) {
        font-size: 18px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 16px;
    }
}
.payment-modal-content-list {
    padding: 0 0 0 20px;

    @media screen and (max-width: $w-max-tablet) {
        padding: 0 0 0 30px;
        margin: 6px 0;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        margin: 6px 0;
    }
}
.payment-modal-images {
    display: flex;
    align-items: flex-end;
    gap: 30px;

    @media screen and (max-width: $w-max-tablet) {
        justify-content: center;
        gap: 20px;

        img {
            max-height: unset;
            max-width: 25%;
        }
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        gap: 20px;

        img {
            max-height: 60px;
            max-width: 50px;
        }
    }
}
.payment-modal-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 440px;
    height: 430px;
    padding: 30px 20px;
    background: #ffffff;
    border-radius: 15px;

    @media screen and (max-width: $w-max-tablet) {
        height: 100%;
        width: 90%;
        padding: 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        height: 100%;
        width: 90%;
        padding: 8px;
    }

    @media screen and (max-width: $w-max-tablet) and (orientation: landscape) {
        width: 94%;
        //margin-left: 8px;
        margin-right: -16px;
        padding-left: 6px;
        padding-right: 6px;
    }
}
.payment-modal-card-heading {
    margin-bottom: 26px;
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    text-align: center;
    color: #333333;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 28px;
        margin-bottom: 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 20px;
        margin-bottom: 0;
    }
}
.payment-modal-card-price-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment-modal-card-price {
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #333;
    margin-top: 3px;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 20px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        margin-top: 2px;
        font-size: 16px;
    }

    &__small {
        font-size: 16px;

        @media screen and (max-width: $w-max-tablet) {
            font-size: 14px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            font-size: 12px;
        }
    }

    &__discount {
        position: absolute;
        display: flex;
        top: 7px;
        transform: translateX(150px);
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        color: #333;
        opacity: 0.4;
        text-decoration: line-through;

        @media screen and (max-width: $w-max-tablet) {
            font-size: 14px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            top: 4px;
            font-size: 12px;
        }
    }
}
.payment-modal-card-price-annually {
    font-family: $secondary-font-family;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #333;
    opacity: 0.7;
    margin-top: 3px;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        margin-top: 2px;
        font-size: 11px;
    }
}
.payment-modal-checkout-btn {
    max-width: 380px;
    width: 100%;
    padding: 16px 3px;
    margin-top: 28px;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    color: #333333e6;
    background: #2be480;
    box-shadow: 0 4px 10px #5725984d;
    border-radius: 50px;
    border: none;
    cursor: pointer;

    &:hover {
        background: darken(#2be480, 10%);
    }

    @media screen and (max-width: $w-max-tablet) {
        max-width: 90%;
        padding: 10px 3px;
        margin-top: 10px;
        font-size: 20px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        max-width: 240px;
        padding: 4px 3px;
        margin-top: 4px;
        font-size: 16px;
    }
}
.payment-modal-checkout-cancelled {
    margin: 8px 0 30px;
    font-weight: 800;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #333;
    opacity: 0.5;

    @media screen and (max-width: $w-max-tablet) {
        margin: 6px 0 16px;
        font-size: 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        margin: 6px 0;
        font-size: 12px;
    }
}
.payment-modal-checkboxes {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-evenly;
    width: 420px;
    margin-bottom: 5px;

    @media screen and (max-width: $w-max-tablet) {
        gap: 10px;
        width: 80%;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        gap: 10px;
        width: 220px;
        margin-bottom: 2px;
    }
}
.payment-modal-checkbox {
    display: flex;
    flex-direction: column;

    label {
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        text-align: center;
        color: #333;

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            font-size: 14px;
            line-height: 110%;
        }

        @media screen and (max-width: $w-max-tablet) {
            font-size: 18px;
        }
    }

    [type='radio']:checked,
    [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
    }

    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: -6px;
        top: 2px;
        width: 24px;
        height: 24px;
        border: 2px solid #333;
        border-radius: 100%;
        background: transparent;

        @media screen and (max-width: $w-max-tablet) {
            left: 0;
            top: 2px;
            width: 18px;
            height: 18px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            left: -2px;
            top: -2px;
            width: 16px;
            height: 16px;
        }
    }

    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
        content: '';
        width: 16px;
        height: 16px;
        background: #333;
        position: absolute;
        top: 8px;
        left: 0;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        @media screen and (max-width: $w-max-tablet) {
            top: 6px;
            left: 4px;
            width: 14px;
            height: 14px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            top: 2px;
            left: 2px;
            width: 12px;
            height: 12px;
        }
    }

    [type='radio']:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    [type='radio']:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}

.payment-modal-checkbox-price-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment-modal-checkbox-price {
    font-family: $secondary-font-family;
    font-weight: 400;
    font-size: 22px;
    text-align: left;
    display: block;
    margin-left: 0;
    color: #333;
    opacity: 0.5;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 16px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 12px;
        margin-left: -14px;
    }

    &__small {
        font-size: 16px;

        @media screen and (max-width: $w-max-tablet) {
            font-size: 16px;
            margin: -6px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            font-size: 10px;
            margin: -4px;
        }
    }

    &__discount {
        position: absolute;
        display: flex;
        top: 7px;
        transform: translateX(150px);
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        color: #333;
        opacity: 0.4;
        text-decoration: line-through;

        @media screen and (max-width: $w-max-tablet) {
            font-size: 14px;
        }

        @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
            font-size: 12px;
        }
    }
}
.payment-modal-checkbox-price-annually {
    font-family: $secondary-font-family;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    display: block;
    color: #333;
    opacity: 0.5;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 14px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 12px;
    }
}

.payment-modal-checkbox-hint-container {
    display: flex;
    gap: 55px;
    margin: 0 0 12px 0;

    @media screen and (max-width: $w-max-tablet) {
        gap: 35px;
        margin-bottom: 10px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        margin-bottom: 4px;
    }

    @media screen and (max-width: $w-max-phone) {
        margin-bottom: 8px;
    }
}
.payment-modal-checkbox-hint {
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    text-align: center;
    padding: 4px;
    color: #ffc75a;
    background: #572598;
    opacity: 0.8;
    border-radius: 6px;

    &.hint-dummy {
        opacity: 0;
    }

    @media screen and (max-width: $w-max-tablet) {
        font-size: 11px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 11px;
    }

    &--hidden {
        font-size: 13px;
        opacity: 0;
    }
}
.payment-modal-text {
    color: #572598;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    opacity: 0.8;

    @media screen and (max-width: $w-max-tablet) {
        font-size: 18px;
        line-height: 20px;
    }

    @media screen and (max-height: $h-max-under-chromebook) and (orientation: landscape) {
        font-size: 14px;
        line-height: 16px;
    }
}
.privacy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 440px;
    margin: 1rem;
    column-gap: 4px;

    a {
        color: white;
        text-decoration: underline;
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 90%;
        margin: 4px 0.5rem 0;
        font-size: 14px;
    }
}
.payment-modal-math-pass {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%) translateY(-50%) rotateZ(-18deg);

    @media screen and (max-width: $w-max-desktop) {
        width: 110px;
    }

    @media screen and (max-height: $h-max-incl-chromebook) {
        width: 96px;
        transform: translateX(-40%) translateY(-35%) rotateZ(-18deg);
    }

    @media screen and (max-height: $h-max-under-chromebook) {
        width: 64px;
        transform: translateX(-40%) translateY(-40%) rotateZ(-18deg);
    }
}
</style>
