const isExperimental = import.meta.env.VITE_ENVIRONMENT === 'experimental';
// prettier-ignore
export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => isExperimental ?
            import('@/landing/flows/ExperimentalLandingPage/HomeContainer.vue') :
            import('@/landing/flows/LandingPage/HomeContainer.vue'),
        meta: {
            authenticate: 'home', //only one not boolean authenticate, cuz home page specially managed by route.name
        },
    },
    {
        path: '/flags',
        component: () => import('./core/pages/Doc/DocFlags.vue'),
        meta: {
            authenticate: true,
        },
        beforeEnter: (to, from, next) => {
            let isDev = localStorage.getItem('dev-tools');

            if (!isDev) {
                return next({ name: 'home' })
            }
            next();
        },
    },
    {
        path: '/login',
        name: 'auth.login',
        props: true,
        component: () =>
            import('@/flows/Authentication/LoginView/LoginView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/login/cert',
        name: 'auth.loginCert',
        props: true,
        component: () =>
            import('@/flows/TimeBasedLiveGame/Host/GameEndedView/components/Podium/LoginViewCertificateWrapper.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/student-demo',
        name: 'student-demo',
        props: true,
        component: () =>
            import('@/teacher/flows/TeacherSoloTrack/StudentDemo.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/register',
        name: 'auth.register',
        props: true,
        component: () =>
            import('@/flows/Authentication/RegisterView/RegisterView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/forgot',
        name: 'auth.forgot',
        props: true,
        component: () =>
            import('@/flows/Authentication/ForgotPasswordView/ForgotPasswordView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/change-password',
        name: 'auth.change-password',
        props: true,
        component: () =>
            import('@/flows/Authentication/ChangePasswordView/ChangePasswordView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/coronavirus-remote-learning',
        name: 'remote-learning',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
    },
    {
        path: '/eriolukord-oppetoos-kaug-ja-koduope/(\\?ref_id{=:upviralId})?',
        name: 'remote-learning-estonia',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
        props: true,
    },
    {
        path: '/eriolukord-oppetoos-kaug-ja-koduope',
        name: 'remote-learning-estonia',
        component: () => import('./landing/flows/RemoteLearning/RemoteLearningContainer.vue'),
        props: true,
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('@/student/flows/JoinGame/JoinGameContainer.vue'),
        children: [
            {
                path: ':gameCode',
                name: 'join.gamecode',
                component: () => import('@/student/flows/JoinGame/JoinGameContainer.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/math-madness',
        name: 'math-madness',
        component: () =>
            import('@/flows/MathMadness/MathMadnessCompetitionPage.vue'),
    },
    {
        path: '/math-madness-leaderboard/:participantId',
        name: 'math-madness-leaderboard',
        component: () =>
            import('@/flows/MathMadness/MadnessLeaderboardPage.vue'),
        props: true,
    },
    {
        path: '/buy-premium/:userId',
        name: 'buy-premium',
        component: () =>
            import('@/flows/PaymentModal2/ExternalPaymentView.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/premium-buy',
        name: 'parent.buy-premium',
        component: () =>
            import('@/student/flows/ParentPaymentModal/ParentPaymentView.vue'),
    },
    {
        path: '/host',
        component: () =>
            import('@/flows/TimeBasedLiveGame/Host/HostContainer.vue'),
        meta: {
            authenticate: true,
        },
        children: [
            {
                path: '',
                name: 'host',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherHome.vue'),
                props: true,
            },
            {
                path: 'dashboard',
                name: 'host.dashboard',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/DashboardView.vue'),
                props: true,
            },
            {
                path: 'play-again/:gameMode/:gameCode',
                name: 'host.play-again',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/DashboardView.vue'),
                props: true,
            },
            {
                path: 'configure/:gameType',
                name: 'host.create-game.configure',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/TopicConfigurationView/TeacherTopicConfigurationView.vue'),
                props: true,
            },
            {
                path: 'choose-game-mode',
                name: 'host.create-game.game-mode',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/components/GameModeSelectionView/TeacherGameModeSelectionView.vue'),
                props: true,
            },
            {
                path: 'create-game',
                name: 'host.create-game',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/CreateGameView/CreateGameView.vue'),
                props: true,
            },
            {
                path: ':gameCode/lobby',
                name: 'host.lobby',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/LobbyView/LobbyView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/lobby/self-play',
                name: 'host.lobby-self-play',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/LobbyView/SelfPlayLobbyView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                },
            },
            {
                path: ':gameCode/self-paced-lobby',
                name: 'host.self-paced-lobby',
                component: () =>
                    import('@/flows/SelfPacedGame/SelfPacedLobbyView/SelfPacedLobbyView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                },
            },
            {
                path: ':gameCode/preview',
                name: 'host.preview',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/PreGameView/PreGameView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/play',
                name: 'host.play',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/GameView/GameView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/results',
                name: 'host.results',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/GameEndedView/GameEndedView.vue'),
                props: true,
                meta: {
                    isGameOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/certificates',
                name: 'host.certificates',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Host/GameEndedView/components/Podium/CertificatePdf.vue'),
                props: true,
            },
            {
                path: ':gameCode/analytics',
                name: 'host.live-analytics',
                component: () =>
                    import('@/flows/GameReports/TblGameReports/TblGameReports.vue'),
                props: true,
                meta: {
                    isReportOwner: true,
                    requireGameState: true,
                },
            },
            {
                path: ':gameCode/self-paced-analytics',
                name: 'host.self-paced-analytics',
                component: () =>
                    import('@/flows/GameReports/SelfPacedGameReportsView/SelfPacedGameReportsView.vue'),
                props: true,
                meta: {
                    requireGameReport: true,
                },
            },
            {
                // Force the route to be /reports, not /host/reports.
                path: '/reports',
                name: 'host.reports',
                component: () =>
                    import('@/flows/GameReports/ReportsView/ReportsView.vue'),
                props: true,
            },
            {
                path: '/reports/sample',
                name: 'host.reports.sample',
                component: () =>
                    import('@/flows/GameReports/ReportsView/ReportsSampleView.vue'),
                props: true,
            },
            {
                path: 'referrals',
                name: 'teacher-referrals',
                component: () =>
                    import('@/flows/ReferralProgram/TeacherReferral.vue'),
                props: true,
            },
            {
                path: ':gameCode/lobby/spg',
                name: 'spg-lobby',
                component: () =>
                    import('./teacher/flows/TeacherDashboard/DashboardView/TopicConfigurationView/MobileFlow/StepInvite.vue'),
                props: true,
            },
            {
                path: ':gameCode/lobby/live',
                name: 'live-lobby',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/DashboardView/TopicConfigurationView/MobileFlow/StepInviteLive.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/teacher',
        name: 'teacher',
        component: () =>
            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/TeacherOnboardingContainer.vue'),
        meta: {
            authenticate: true,
            requireTeacherRole: true,
        },
        children: [
            {
                path: 'profile-setup',
                name: 'teacher.profile-setup',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/SetupViews/TeacherProfileSetupView.vue'),
                props: true,
            },
            {
                path: 'demo/join',
                name: 'student.demo.join',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/TeacherOnboardingStudentJoinDemoView.vue'),
                props: true,
            },
            {
                path: 'live-game',
                name: 'student-demo.live-game',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/LiveGameContainer.vue'),
                children: [
                    {
                        path: 'avatar-selection/:code',
                        name: 'student-demo.livegame.avatar-selection',
                        component: () =>
                            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/AvatarSelectionView.vue'),
                        props: true,
                    },
                    {
                        path: 'preview/:code',
                        name: 'student-demo.livegame.preview',
                        component: () =>
                            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/PreGameView/PreGameView.vue'),
                        props: true,
                    },
                    {
                        path: 'results/:code',
                        name: 'student-demo.livegame.results',
                        component: () =>
                            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/GameEndedView.vue'),
                        props: true,
                    },
                    {
                        path: 'play/:code',
                        name: 'student-demo.livegame.gameview',
                        component: () =>
                            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LiveGame/GameView/GameView.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'demo/:gameCode/lobby',
                name: 'teacher.demo.lobby',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/LobbyView.vue'),
                props: true,
            },
            {
                path: 'demo/:gameCode/play',
                name: 'teacher.demo.play',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/GameView.vue'),
                props: true,
            },
            {
                path: 'demo/:gameCode/results',
                name: 'teacher.demo.results',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/GameEndedView.vue'),
                props: true,
            },
            {
                path: 'demo/:gameCode/report',
                name: 'teacher.demo.report',
                component: () =>
                    import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/DemoViews/ReportView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/teacher-register',
        name: 'teacher-register',
        component: () =>
            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/RegisterViews/TeacherRegisterViewV2.vue'),
        props: true,
    },
    {
        path: '/clever-library-register',
        name: 'clever-library-register',
        component: () =>
            import('@/teacher/flows/TeacherDashboard/TeacherOnboardingContainer/CleverLibraryViews/CleverLibraryView.vue'),
        props: true,
    },
    {
        path: '/live-game',
        name: 'live-game',
        component: () =>
import('@/flows/TimeBasedLiveGame/Player/LiveGame/LiveGameContainer.vue'),
        children: [
            {
                path: 'preview/:code',
                name: 'livegame.preview',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/PreGameView/PreGameView.vue'),
                props: true,
            },
            {
                path: 'results/:code',
                name: 'livegame.results',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/GameEndedView.vue'),
                props: true,
            },
            {
                path: 'play/:code',
                name: 'livegame.gameview',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/GameView/GameView.vue'),
                props: true,
            },
            {
                path: 'warmup/:code',
                name: 'livegame.warmup',
                component: () =>
                    import('@/flows/TimeBasedLiveGame/Player/LiveGame/WarmUpView/WarmUpView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/self-paced-game',
        name: 'self-paced-game',
        component: () =>
            import('@/flows/SelfPacedGame/SPGContainer.vue'),
        children: [
            {
                path: 'preview/:code',
                name: 'self-paced-playlist.preview',
                component: () =>
                    import('@/flows/SelfPacedGame/PreGameView/PreGamePlaylistView.vue'),
                props: true,
            },
            {
                path: 'preview/:code',
                name: 'self-paced-game.preview',
                component: () =>
                    import('@/flows/SelfPacedGame/PreGameView/PreGameView.vue'),
                props: true,
            },
            {
                path: 'results/:code',
                name: 'self-paced-game.results',
                component: () =>
                    import('@/flows/SelfPacedGame/GameEndedView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/player',
        name: 'player',
        component: () =>
            import('@/flows/SelfPacedGame/PlayerContainer.vue'),
        children: [
            {
                path: ':gameCode/self-paced/play',
                name: 'player.self-paced-play',
                component: () => import('@/flows/SelfPacedGame/SelfPacedGameView/SelfPacedGameView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/student/:studentId/report',
        name: 'student.report',
        props: true,
        component: () => import('@/teacher/flows/ClassLists/IndividualProgressView/IndividualProgressView.vue'),
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/profile',
        component: () =>
            import('@/flows/User/UserContainer.vue'),
        meta: {
            authenticate: true,
        },
        children: [
            {
                path: '',
                name: 'user-profile',
                component: () =>
                    import('@/flows/User/ProfileView/UserProfileView.vue'),
                props: true,
            },
            {
                path: 'progress',
                name: 'user-profile.progress',
                component: () =>
                    import('@/flows/User/ProgressView/ProgressView.vue'),
                props: true,
            },
            {
                path: '/profile/teachers-avatar',
                name: 'user-profile.teachers-avatar',
                component: () =>
import('@/flows/User/ProfileView/components/SetTeachersAvatar/components/TeachersAvatarsPage.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/profile/public/:userId/:userPlayerName',
        name: 'user-profile.public.with-player-name',
        component: () =>
            import('@/student/pages/PublicProfileView/PublicProfileView.vue'),
        props: true,
    },
    {
        path: '/profile/public/:userId',
        name: 'user-profile.public',
        component: () =>
            import('@/student/pages/PublicProfileView/PublicProfileView.vue'),
        props: true,
    },
    {
        path: '/classes',
        component: () =>
            import('@/teacher/flows/ClassLists/ClassListContainer.vue'),
        meta: {
            authenticate: true,
            requireTeacherRole: true,
        },
        children: [
            {
                path: '',
                name: 'classes.class-overview',
                component: () => import('@/teacher/flows/ClassLists/ClassOverview/ClassOverview.vue'),
                props: true,
            },
            {
                path: 'create/class',
                name: 'classes.create.class',
                component: () =>
                    import('@/teacher/flows/ClassLists/CreateView/CreateClass.vue'),
                props: true,
            },
            {
                path: ':classCode/studai',
                name: 'classes.class-student-access-info',
                component: () =>
                    import('@/teacher/flows/ClassLists/AccessInfoView/ClassroomStudentAccessInformation.vue'),
                props: true,
            },
            {
                path: ':classCode/individual-report/:studentId',
                name: 'classes.individual-report',
                component: () => import('@/teacher/flows/ClassLists/IndividualProgressView/IndividualProgressView.vue'),
                props: true,
            },
            {
                path: ':classCode',
                name: 'classes.class-overview.details',
                component: () => import('@/teacher/flows/ClassLists/ClassOverview/ClassOverview.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/invite/:userId',
        name: 'user-invite-link',
        component: () =>
            import('@/flows/ReferralProgram/UserInviteLink.vue'),
        props: true,
        meta: {
            authenticate: false,
        },
    },
    {
        /**
         * Set flags to current logged-in user.
         */
        path: '/flags/unlock/:flag',
        component: () =>
            import('@/core/helpers/FlagUnlocker.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        /**
         * Set flags for client who hopefully signs up and then these flags
         * will be saved to the user
         */
        path: '/test/:flag',
        component: () =>
            import('@/core/helpers/FlagUnlocker.vue'),
        props: true,
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/:ID',
        name: 'partner-tracking',
        component: () =>
            import('@/flows/ReferralProgram/ReferralView.vue'),
        props: true,
    },
    {
        path: '/enable/clever/flag',
        name: 'clever-flag',
        component: () =>
            import('@/core/helpers/CleverUnlocker.vue'),
        meta: {
            authenticate: false,
        },
    },
    {
        path: '/tooling/live/admin',
        name: 'clever-flag',
        component: () =>
            import('@/core/helpers/LiveGameTooling.vue'),
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/games-list',
        name: 'games-list',
        component: () =>
            import('@/flows/MathRunner/GameList/GameList.vue'),
    },
    {
        path: '/create-game',
        name: 'runner.create-game',
        component: () =>
            import('@/flows/MathRunner/GameCreation/GameCreation.vue'),
    },
    {
        path: '/online-players',
        name: 'online-players',
        component: () =>
            import('@/flows/MathRunner/OnlinePlayersList/OnlinePlayersList.vue'),
    },
    {
        path: '/independent-work',
        name: 'independent-work',
        component: () =>
            import('@/teacher/flows/TeacherSoloTrack/SoloModePage/SoloModePage.vue'),
        props: true,
    },
    {
        path: '/home-play',
        name: 'home-play',
        component: () =>
            import('@/teacher/flows/HomePlay/HomePlay.vue'),
        props: true,
    },
    {
        path: '/class-pdf',
        name: 'class-pdf',
        component: () =>
            import('@/teacher/flows/HomePlay/StudentsInClassPDF.vue'),
        props: true,
    },
    {
        path: '/myclass/:classCode',
        name: 'join-class',
        component: () =>
            import('@/teacher/flows/HomePlay/JoinClass.vue'),
        props: true,
    },
    {
        path: '/premium',
        name: 'premium',
        component: () =>
            import('@/teacher/flows/TeacherPremium/TeacherPremiumPage.vue'),
        props: true,
    },
    {
        /**
         * Set premium to current logged in teacher.
         */
        path: '/unlock/premium',
        component: () =>
            import('@/core/helpers/TeacherPremiumUnlocker.vue'),
        props: true,
        meta: {
            authenticate: true,
        },
    },
    {
        path: '/gomobile',
        name: 'gomobile',
        component: () => import('@/student/widgets/MobileAppPromo/MobileAppRedirect.vue'),
    },
];
