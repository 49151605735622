import SecureApi from '@/flows/Authentication/services/SecureApi';
export default {
    namespaced: true,
    state: () => ({}),
    mutations: {},
    actions: {
        claimAvatar: async ({ rootGetters }, item) => {
            if (!item || !item.rarity) return;

            if (item.rarity === 'common') return;

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            const avatarData = rootGetters['v2/homegame/avatarData'];

            const classCode = rootGetters['v2/user/getClassCode'];

            const playerName = rootGetters['v2/user/playerName'];

            const userId = rootGetters['v2/user/uid'];

            const sseData = {
                type: `unlockedAvatar${capitalizeFirstLetter(item.rarity)}`,
                receiverId: classCode,
                userInfo: {
                    playerName,
                    userId,
                    ...avatarData,
                },
                metadata: {},
            };

            await SecureApi().post(`sse/emit/solo-track/${classCode}`, sseData);
        },
    },
};
