import HostGetters from '@/flows/TimeBasedLiveGame/Host/store/HostGetters';
import PlayerGetters from '@/flows/TimeBasedLiveGame/Player/store/PlayerGetters';
import TimerGetters from '@/core/components/Timer/store/TimerGetters';
import LanguageSwitcherGetters from '@/core/components/LanguageSwitcher/store/LanguageSwitcherGetters';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import ClassListGetters from '@/teacher/flows/ClassLists/ClassListGetters';
import Auth from '@/store/modules/auth.js';
import moment from 'moment';
import CONSTANTS from '@/core/helpers/constants';

export default {
    ...Auth.getters,
    ...HostGetters,
    ...PlayerGetters,
    ...TimerGetters,
    ...LanguageSwitcherGetters,
    ...ClassListGetters,
    getPreGameMode({ createGame }) {
        return createGame.preGameMode;
    },
    getGameCode({ gameCode }) {
        return gameCode;
    },
    getJoinGameCode({ gameState, joinGameCode }) {
        return gameState?.gameInfo?.joinGameCode || joinGameCode;
    },
    getCurrentRound({ gameState }) {
        return gameState.round.currentRound;
    },
    isRoundFinished({ gameState }) {
        return gameState.round.roundFinished;
    },
    getRoundsCount({ gameState }) {
        return gameState.round.roundsCount;
    },
    getRoundDuration({ gameState }) {
        return (
            gameState.gameInfo?.roundDuration ||
            gameState.options?.duration * 60 ||
            (gameState.gameInfo?.rounds?.data &&
                gameState.gameInfo.rounds.data[
                    gameState.gameInfo.rounds.data.length - 1
                ].roundDuration)
        );
    },
    getCurrentRoundEndTime({ gameState }) {
        return gameState.round.endTime;
    },
    getGameInfo({ gameState }) {
        return gameState.gameInfo;
    },
    getClientType({ clientType }) {
        return clientType;
    },
    getAudio({ audio }) {
        return audio;
    },
    getReferral({ referral }) {
        return referral;
    },
    getTeachingGrades({ teachingGrades }) {
        return teachingGrades;
    },
    getGameTasks({ gameState }) {
        return gameState.tasks;
    },
    getGameMode({ gameState }) {
        return gameState.mode;
    },
    getGameVariant({ gameState }) {
        return gameState.variant;
    },
    getGameOptions({ gameState }) {
        return gameState.options;
    },
    isSelfPlaying(state) {
        if (!state.user) {
            return false;
        }

        const creator =
            state.v2.game.mode === CONSTANTS.LIVE_GAME_MODE
                ? state?.v2?.game?.info?.userId
                : state.gameState?.gameInfo
                  ? state.gameState.gameInfo?.userId
                  : null;

        return creator && creator === state.user?.userId;
    },
    isGameCreatedByTeacher({ gameState, gameCreatorRole }) {
        return (
            gameCreatorRole === 'teacher' ||
            gameState.gameInfo?.creatorRole === 'teacher'
        );
    },
    isSelfPacedGameCreatedByATeacher({ gameState }, getters) {
        return (
            ['self-paced', 'self-paced-playlist'].includes(gameState.mode) &&
            getters.isGameCreatedByTeacher
        );
    },
    isGameCreatedByATeacherAndPlayedByAStudent(state) {
        return (
            state.gameState.gameInfo.creatorRole === 'teacher' &&
            state.user &&
            state.user.role === 'student'
        );
    },
    isHomeGame(state) {
        if (!state.user || !state.gameState.gameInfo.userId) {
            return null;
        }

        return (
            state.gameState.gameInfo.userId === state.user.userId &&
            state.user.role === 'student'
        );
    },
    getReferringUserId({ referralUserId }) {
        return referralUserId;
    },
    showArrowButtons(state, getters) {
        let type, generator;

        const gameInfo = getters.getGameInfo;

        if (gameInfo && gameInfo.type) {
            type = gameInfo.type;

            generator = gameInfo.numberGenerator;
        } else {
            const selectedGameType = getters.getSelectedGameType;

            const selectedNumberGenerator = getters.getSelectedNumberGenerator;

            if (
                selectedGameType &&
                selectedGameType.type &&
                selectedNumberGenerator
            ) {
                type = getters.getSelectedGameType.type;
                generator = selectedNumberGenerator;
            }
        }

        if (!type) {
            return false;
        }

        const GameTopic = TopicsFactory.getStaticTopicClass(type);

        if (GameTopic) {
            return GameTopic.showArrowButtons(generator);
        }

        console.error('showArrowButtons', 'no GameTopic!');

        return false;
    },
    isWebsocketConnected({ websocket }) {
        return websocket.status.isConnected;
    },
    shouldWebsocketBeConnected({ websocket }) {
        return websocket.status.shouldBeConnected;
    },
    getWebsocketReconnectionAttemptCount({ websocket }) {
        return websocket.status.reconnectCounter;
    },
    isBefore3PM(state, getters) {
        return getters.isSchoolTime(moment());
    },
    /**
     * Parameterized getter to be called as a function
     * to avoid outdated cached result.
     */
    isSchoolTime(state) {
        return (momentToTest) => {
            // check time only if not weekend
            if (typeof state.forcedBefore3PM === 'boolean') {
                return state.forcedBefore3PM;
            }

            const today = new Date().getDay();

            if (today > 0 && today < 6) {
                const now = momentToTest || moment();

                const hours = now.hours();

                const minutes = now.minutes();

                const afterStartOfSchool =
                    hours === 7 ? minutes >= 30 : hours > 7;

                const beforeEndOfSchool =
                    hours === 15 ? minutes < 30 : hours < 15;

                return afterStartOfSchool && beforeEndOfSchool;
            } else {
                return false;
            }
        };
    },
    isAfter4PM() {
        return moment().hours() >= 16;
    },
    gameSoundPlaying(state) {
        return state.gameSoundPlaying;
    },
    subscribedUser(state) {
        if (!state.user) {
            return false;
        }

        return ['active', 'trialing'].includes(
            state.user?.paymentInfo?.stripe?.subscription?.status,
        );
    },
    claimedRewards(state) {
        return state.user?.studentInfo?.battlepass?.claimedRewards || [];
    },
    claimedFrames(state, getters) {
        return [
            'no-frame',
            'wings-dark',
            'wings-light',
            ...getters.claimedRewards.filter((reward) =>
                reward.includes('frame'),
            ),
        ];
    },
};
