/* global mixpanel */
export const SHOP_MP_EVENTS = {
    VIEWED_SHOP_PAGE: 'viewedShopPage',
    VIEWED_COLLECTION_PAGE: 'viewedCollectionPage',
    CHOSE_MBUCKS_PACK: 'choseMBucksPack',
    BOUGHT_MBUCKS_PACK: 'boughtMBucksPack',
    CHOSE_AICREDITS: 'choseAiCredits',
    BOUGHT_AICREDITS: 'boughtAiCredits',
    BOUGHT_ITEM_IN_COLLECTION: 'boughtItemInCollection',
    BOUGHT_BACKDROP_IN_PARK: 'boughtBackdropInPark',
    BOUGHT_ENERGY_REFILL: 'boughtEnergyRefill',
    USED_FREE_ENERGY_REFILL: 'usedFreeEnergyRefill',
    ATTEMPTED_TO_BUY: 'attemptedToBuy',
    SEES_PARENTAL_GATE: 'seesParentalGate',
    SOLVED_PARENTAL_GATE: 'solvedParentalGate',
    SET_MBUCKS: 'setMbucks',
    CONVERTED_MBUCKS: 'convertedMBucks',
    VIEWED_EXCHANGE_PAGE: 'viewedExchangePage',
};

export const SHOP_MP_METHODS = {
    viewedShopPage() {
        mixpanel.track('Viewed shop page');
    },
    viewedCollectionPage() {
        mixpanel.track('Viewed collection page');
    },
    choseMBucksPack({ amount, price }) {
        mixpanel.track('Chose mBucks pack', { Amount: amount, Price: price });
    },
    boughtMBucksPack({ amount, price, mBucks }) {
        mixpanel.track('Bought  mBucks pack', {
            Amount: amount,
            Price: price,
            'mBucks before': mBucks - amount,
            'mBucks after': mBucks,
        });
        mixpanel.people.increment('mBucks amount', amount);
    },
    choseAiCredits({ amount, price }) {
        mixpanel.track('Chose aiCredits pack', {
            Amount: amount,
            Price: price,
        });
    },
    boughtAiCredits({ amount, price, aiCredits }) {
        mixpanel.track('Bought aiCredits', {
            Amount: amount,
            Price: price,
            before: aiCredits - amount,
            after: aiCredits,
        });
        mixpanel.people.increment('aiCredits amount', amount);
    },
    boughtItemInCollection({ category, itemName, price, mBucks }) {
        mixpanel.track('Bought an item in Collection', {
            Category: category,
            'Item name': itemName,
            'mBucks before': mBucks + price,
            'mBucks after': mBucks,
        });
        mixpanel.people.increment('mBucks amount', -price);
    },
    boughtBackdropInPark({ category, itemName, price, mBucks }) {
        mixpanel.track('Bought background in park', {
            Category: category,
            'Item name': itemName,
            'mBucks before': mBucks + price,
            'mBucks after': mBucks,
        });
        mixpanel.people.increment('mBucks amount', -price);
    },
    boughtEnergyRefill({ category, itemName, price, mBucks }) {
        mixpanel.track('Bought an energy Refill', {
            Category: category,
            'Item name': itemName,
            'mBucks before': mBucks + price,
            'mBucks after': mBucks,
        });
        mixpanel.people.increment('mBucks amount', -price);
    },
    usedFreeEnergyRefill({ category, itemName, subscribedUser }) {
        mixpanel.track('Used free energy Refill', {
            Category: category,
            'Item name': itemName,
            'Subscribed user': subscribedUser,
        });
    },
    attemptedToBuy({
        success,
        paymentMethod,
        cameFrom,
        productName,
        productPrice,
        productAmount,
    }) {
        mixpanel.track('Attempted to buy', {
            Success: success,
            'Payment method': paymentMethod,
            'Came from': cameFrom,
            'Product name': productName,
            'Product price': productPrice,
            'Product interval': 'one-time-purchase',
            'Product amount': productAmount,
        });
    },
    seesParentalGate() {
        mixpanel.track('Sees Parental Gate');
    },
    solvedParentalGate() {
        mixpanel.track('Solved the Parental Gate');
    },
    setMbucks(mbucks) {
        mixpanel.people.set({ MBucks: mbucks });
        mixpanel.register({ MBucks: mbucks });
    },
    convertedMBucks(conversion) {
        const { from, to } = conversion;
        const humanReadable = {
            energy: 'Solo Energy',
            arenaEnergy: 'Arena Energy',
            aiCredits: 'AI Credits',
            gems: 'mBucks',
        };
        const fromC = humanReadable[from.currency];
        const toC = humanReadable[to.currency];
        mixpanel.track(`Converted ${fromC} to ${toC}`);
    },
    viewedExchangePage() {
        mixpanel.track(`Viewed exchange page`);
    },
};
