<template>
    <div class="privacy">
        <a :href="$getTermsOfUseLink" target="_blank" rel="noopener">
            {{ $t('auth.termsAndConditions') }}
        </a>

        {{ $t('general.and') }}

        <a :href="$getPrivacyPolicyLink" target="_blank" rel="noopener">
            {{ $t('auth.privacyPolicy') }}
        </a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        unauthorizedAccount: Boolean,
        unauthorizedUserName: String,
        lastStudentClass: Object,
        priceTestVersion: Array,
        teacherInfo: Object,
    },
    emits: ['nextStep', 'selectedPlanChange'],
    data() {
        return {
            selectedPriceOption: null,
        };
    },
    computed: {
        ...mapGetters({
            currentLanguage: 'getCurrentLanguage',
        }),
    },
};
</script>
<style lang="scss">
.privacy {
    align-self: center;
    bottom: var(--safe-area-inset-bottom);

    a {
        color: white;
        text-decoration: underline;
    }
}
</style>
