<template>
    <div>
        <GetPremiumModal v-if="premiumModalMode" :mode="premiumModalMode" />
        <WelcomeTrialModal v-if="showWelcomePremiumModal" />
        <PremiumTrialEndModal v-if="showPremiumTrialEndModal" />
        <EmailSentModal v-if="showEmailSentModal" />
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import GetPremiumModal from '@/teacher/flows/TeacherPremium/Modals/GetPremiumModal.vue';
import WelcomeTrialModal from '@/teacher/flows/TeacherPremium/Modals/WelcomeTrialModal.vue';
import EmailSentModal from '@/teacher/flows/TeacherPremium/Modals/EmailSentModal.vue';
import PremiumTrialEndModal from '@/teacher/flows/TeacherPremium/Modals/PremiumTrialEndModal.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment/moment';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';

export default defineComponent({
    components: {
        PremiumTrialEndModal,
        EmailSentModal,
        WelcomeTrialModal,
        GetPremiumModal,
    },
    data() {
        return {
            premiumTrialEndPollerTimeout: null,
        };
    },
    computed: {
        ...mapState(['user']),
        ...mapState('v2/teacherPremium', [
            'hasSchoolPremiumTrial',
            'showEmailSentModal',
            'showPremiumTrialEndModal',
            'showWelcomePremiumModal',
            'premiumModalMode',
            'trialMinutes',
        ]),
    },
    watch: {
        user(newVal, oldVal) {
            if (!oldVal && newVal) {
                this.premiumTrialEndCheckPoller();
            }
        },
        $route() {
            if (this.$isTeacher && this.$showDiscoverPremium) {
                if (this.$route.query?.premiumTrialWelcome) {
                    if (this.user?.premium?.status === 'trial') {
                        new TrackingService().track(
                            EVENTS.WELCOME_PREMIUM_TRIAL_MODAL_OPENED_BY_LINK,
                            {
                                location: this.$route.path,
                            },
                        );

                        this.$router.push({ path: this.$route.path });

                        this.setShowWelcomePremiumModal(true);
                    } else {
                        this.$router.push({ path: this.$route.path });
                    }
                }

                if (this.$route.query?.premiumTrialEnd) {
                    if (
                        this.user?.premium?.status === 'inactive' &&
                        this.user?.premium?.deActivatedAt
                    ) {
                        new TrackingService().track(
                            EVENTS.PREMIUM_TRIAL_END_MODAL_OPENED_BY_LINK,
                            {
                                location: this.$route.path,
                            },
                        );

                        this.$router.push({ path: this.$route.path });

                        this.setShowPremiumTrialEndModal(true);
                    } else {
                        this.$router.push({ path: this.$route.path });
                    }
                }

                if (this.$route.query?.premiumDiscover) {
                    if (
                        this.user?.premium?.status === 'inactive' ||
                        !this.user?.premium
                    ) {
                        new TrackingService().track(
                            EVENTS.PREMIUM_TRIAL_DISCOVER_MODAL_OPENED_BY_LINK,
                            {
                                location: this.$route.path,
                            },
                        );

                        this.$router.push({ path: this.$route.path });

                        this.setPremiumModalMode('getPremium');
                    }
                }

                if (this.$route.query?.premiumTrialModal) {
                    if (this.user?.premium?.status === 'trial') {
                        new TrackingService().track(
                            EVENTS.PREMIUM_TRIAL_WELCOME_OR_PREMIUM_MODAL_OPENED_BY_LINK,
                            {
                                location: this.$route.path,
                                modal: 'Premium Trial Welcome modal',
                            },
                        );

                        this.$router.push({ path: this.$route.path });

                        this.setShowWelcomePremiumModal(true);
                    } else if (
                        this.user?.premium?.status === 'inactive' &&
                        this.user?.premium?.deActivatedAt
                    ) {
                        new TrackingService().track(
                            EVENTS.PREMIUM_TRIAL_WELCOME_OR_PREMIUM_MODAL_OPENED_BY_LINK,
                            {
                                location: this.$route.path,
                                modal: 'Premium Trial End modal',
                            },
                        );

                        this.$router.push({ path: this.$route.path });

                        this.setShowPremiumTrialEndModal(true);
                    } else {
                        this.$router.push({ path: this.$route.path });
                    }
                }
            } else if (!this.user) {
                if (this.$route.query?.premiumTrialWelcome) {
                    this.$router.push({
                        path: '/login',
                        query: {
                            premiumTrialWelcome: true,
                        },
                    });
                }

                if (this.$route.query?.premiumTrialEnd) {
                    this.$router.push({
                        path: '/login',
                        query: {
                            premiumTrialEnd: true,
                        },
                    });
                }

                if (this.$route.query?.premiumDiscover) {
                    this.$router.push({
                        path: '/login',
                        query: {
                            premiumDiscover: true,
                        },
                    });
                }

                if (this.$route.query?.premiumTrialModal) {
                    this.$router.push({
                        path: '/login',
                        query: {
                            premiumTrialModal: true,
                        },
                    });
                }
            }
        },
        deep: true,
        immediate: true,
    },
    created() {
        this.premiumTrialEndCheckPoller();
    },
    methods: {
        ...mapActions('v2/teacherPremium', [
            'setShowPremiumTrialEndModal',
            'setShowWelcomePremiumModal',
            'setHasSchoolPremiumTrial',
            'calcPremiumTrialTimeLeft',
            'setPremiumModalMode',
        ]),
        premiumTrialEndCheckPoller() {
            if (this.user && this.user.role === 'teacher') {
                if (
                    this.user.premium?.status === 'inactive' ||
                    !this.user.premium
                ) {
                    this.handleClearTimeout();

                    this.premiumTrialEndPollerTimeout = setTimeout(
                        this.premiumTrialEndCheckPoller,
                        1000,
                    );
                } else if (this.user.premium?.status === 'trial') {
                    if (!this.hasSchoolPremiumTrial) {
                        this.setHasSchoolPremiumTrial(true);
                    }

                    this.calcPremiumTrialTimeLeft();

                    this.premiumTrialEndCheck();

                    this.handleClearTimeout();

                    this.premiumTrialEndPollerTimeout = setTimeout(
                        this.premiumTrialEndCheckPoller,
                        1000,
                    );
                }
            }
        },
        async premiumTrialEndCheck() {
            if (this.user?.premium?.activatedAt) {
                const trialEndTime = moment(
                    this.user?.premium?.activatedAt,
                ).add(this.trialMinutes, 'minutes');

                if (moment().isAfter(trialEndTime)) {
                    this.setHasSchoolPremiumTrial(false);

                    this.setShowPremiumTrialEndModal(true);

                    await SecureApi().post('/user/premium', {
                        status: 'inactive',
                    });

                    await fetchAndUpdateUser();
                }
            }
        },
        handleClearTimeout() {
            clearTimeout(this.premiumTrialEndPollerTimeout);

            this.premiumTrialEndPollerTimeout = null;
        },
    },
});
</script>
