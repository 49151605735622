import Api from '@/core/services/Api';
import { jclone } from '@/core/helpers/utils';

export default {
    namespaced: true,
    state: {
        currentGame: null,
        lastGame: null,
    },
    mutations: {
        currentGame: (state, next) => {
            state.currentGame = next;
        },
        lastGame: (state, next) => {
            state.lastGame = next;
        },
        loadCache: (state, cache) => {
            if (!cache) return;

            Object.keys(cache).forEach((key) => {
                state[key] = cache[key];
            });
        },
        resetStateToDefault: (state) => {
            state.currentGame = null;

            state.lastGame = null;
        },
    },
    actions: {
        init: async (store) => {
            console.log('store::player::init');

            store.dispatch('loadCache');
            store.dispatch('update');
        },
        loadCache: (store) => {
            console.log('store::player::loadCache');

            const jcache = sessionStorage.getItem('math99/cache/player');

            if (!jcache) return;

            let cache = null;

            try {
                cache = JSON.parse(jcache);
            } catch (err) {
                cache = null;
                console.error('store::homegame loading cache error', err);
            }

            if (!cache) return;

            store.commit('loadCache', cache);
        },
        saveCache: (store) => {
            console.log('store::player::saveCache');

            const cache = jclone({ ...store.state });

            sessionStorage.setItem(
                'math99/cache/player',
                JSON.stringify(cache),
            );
        },
        clearCache: () => {
            console.log('store::player::clearCache');

            sessionStorage.removeItem('math99/cache/player');
        },
        reset: (store) => {
            console.log('store::player::reset');

            store.commit('resetStateToDefault');
        },
        update: async (store) => {
            console.log('store::player::update');

            await store.dispatch('v2/game/spg/loadHomework', null, {
                root: true,
            });
        },
        startGame: async (store, game) => {
            store.commit('currentGame', game);

            store.dispatch('saveCache');
        },
        endGame: async (store) => {
            store.commit('lastGame', store.state.currentGame);

            store.commit('currentGame', null);

            store.dispatch('saveCache');
        },
    },
};
